import React from "react";
import {useTranslation} from "react-i18next";

export function How() {
  const { t } = useTranslation();
  return (
    <section id="how" className="py-14 bg-neutral-white lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid mb-12 lg:grid-cols-2 lg:mb-8">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              {t('how.how')}
            </h2>
            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base">
              {t('how.how-to-get-attest')}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-9 lg:gap-6 lg:grid-cols-5">
          <div className="justify-center text-center bg-neutral-light-grayish-blue rounded-lg">
            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-0 lg:text-xl lg:font-bold">
              {t('how.step')} 1
            </h2>
            <p className="text-sm font-light leading-5 text-neutral-grayish-blue p-4">
              {t('how.1')}
            </p>
          </div>

          <div className="justify-center text-center bg-neutral-light-grayish-blue rounded-lg">
            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-0 lg:text-xl lg:font-bold">
              {t('how.step')} 2
            </h2>
            <p className="text-sm font-light leading-5 text-neutral-grayish-blue p-4">
              {t('how.2')}
            </p>
          </div>

          <div className="justify-center text-center bg-neutral-light-grayish-blue rounded-lg">
            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-0 lg:text-xl lg:font-bold">
              {t('how.step')} 3
            </h2>
            <p className="text-sm font-light leading-5 text-neutral-grayish-blue p-4">
              {t('how.3')}
            </p>
          </div>

          <div className="justify-center text-center bg-neutral-light-grayish-blue rounded-lg">
            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-0 lg:text-xl lg:font-bold">
              {t('how.step')} 4
            </h2>
            <p className="text-sm font-light leading-5 text-neutral-grayish-blue p-4">
              {t('how.4')}
            </p>
          </div>

          <div className="justify-center text-center bg-neutral-light-grayish-blue rounded-lg">
            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-0 lg:text-xl lg:font-bold">
              {t('how.step')} 5
            </h2>
            <p className="text-sm font-light leading-5 text-neutral-grayish-blue p-4">
              {t('how.5')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
