import React from "react";
import { Link } from "react-router-dom";
import {PageNames} from "./pages";
import {useTranslation} from "react-i18next";

export enum FetchDataState {
  LOADING = "LOADING",
  FAILED = "FAILED",
  SUCCESSFUL = "SUCCESSFUL",
}

export function FetchingNRNFailed({showFailure}: { showFailure: boolean }) {
  const {t} = useTranslation();

  return <div hidden={!showFailure}>
    <p className="text-center my-4">
      {t("orderForm.identificationFailed")}
    </p>
    <p className="text-center my-4">
      <Link
        className="text-primary-sky-blue hover:text-green-700"
        to={PageNames.ITSME}>
        {t("orderForm.tryAgain")}
      </Link>
    </p>
    <p className="text-center my-4">
      <span className="block">{t("orderForm.orContactUs")}</span>
      <a className="text-primary-sky-blue hover:text-green-700 whitespace-nowrap"
         href="mailto:info@rentattest.be">info@rentattest.be</a>
    </p>
  </div>;
}
