import {Navbar} from "../components/Navbar";
import React from "react";
import Footer from "../components/Footer";
import {useTranslation} from "react-i18next";

export function AlgemeneVoorwaarden() {
  const {t} = useTranslation();

  const items = [
    "def",
    "scope",
    "purpose",
    "use",
    "gdpr",
    "limitation-liability",
    "obligation-of-means",
    "complaints-appeals",
    "content",
    "ipr",
    "applicable-law",
  ]
  return (
    <div className="flex flex-col justify-center min-h-screen mt-32">
      <Navbar/>
      <div className="container relative lg:block pb-10 policy">
        <h1>{t("tac.title")}</h1>

        {items.map(value =>
          <section className="mb-8" key={value}>
            <h2 className="text-2xl font-semibold mb-4">{t(`tac.${value}.title`)}</h2>
            <ol className="list-decimal pl-5 space-y-2">
              {t<string, any, any>(`tac.${value}.items`, {
                returnObjects: true,
                interpolation: {escapeValue: false}
              }).map((item: any, index: any) => (
                <li key={index} dangerouslySetInnerHTML={{__html: item}}/>
              ))}
            </ol>
          </section>
        )}
      </div>
      <Footer/>
    </div>
  );
}
