import React from "react";
import {Navbar} from "../components/Navbar";
import Footer from "../components/Footer";

export function PrivacyPolicy() {
  return (
    <div className="flex flex-col justify-center min-h-screen mt-32">
      <Navbar/>
      <div className="container relative min-h-screen lg:block pb-10 policy">

        <h1 className="text-4xl font-bold mb-8 text-gray-800">Privacy Statement</h1>

        <p className="mb-4 text-gray-600">This privacy statement was last updated on September 17, 2024 and applies to
          citizens and legal permanent residents of the European Economic Area and Switzerland.</p>

        <p className="mb-4">In this privacy statement, we explain what we do with the data we obtain about you via
          www.rentattest.be. We recommend you carefully read this statement. In our processing, we comply with the
          requirements of privacy legislation. That means, among other things, that:</p>

        <ul className="list-disc pl-5 mb-4">
          <li>we clearly state the purposes for which we process personal data. We do this by means of this privacy
            statement;
          </li>
          <li>we aim to limit our collection of personal data to only the personal data required for legitimate
            purposes;
          </li>
          <li>we first request your explicit consent to process your personal data in cases requiring your consent;</li>
          <li>we take appropriate security measures to protect your personal data and also require this from parties
            that process personal data on our behalf;
          </li>
          <li>we respect your right to access your personal data or have it corrected or deleted, at your request.</li>
        </ul>

        <p className="mb-8">If you have any questions or want to know exactly what data we keep of you, please contact
          us.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Purpose, data and retention period</h2>

        <p className="mb-4">We may collect or receive personal information for a number of purposes connected with our
          business operations which may include the following:</p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">1.1. Contact - Through phone, mail, email and/or
          webforms</h3>

        <p className="mb-2">For this purpose we use the following data:</p>
        <ul className="list-disc pl-5 mb-4">
          <li>A first and last name</li>
          <li>A home or other physical address, including street name and name of a city or town</li>
          <li>An email address</li>
          <li>A telephone number</li>
        </ul>

        <p className="mb-2">The basis on which we may process these data is:</p>
        <ul className="list-disc pl-5 mb-4">
          <li>Upon the provision of consent.</li>
        </ul>

        <p className="mb-4">Retention period: We retain this data until the service is terminated.</p>

        <h3 className="text-xl font-semibold mb-2 text-gray-800">1.2. Newsletters</h3>

        <p className="mb-2">For this purpose we use the following data:</p>
        <ul className="list-disc pl-5 mb-4">
          <li>An email address</li>
        </ul>

        <p className="mb-2">The basis on which we may process these data is:</p>
        <ul className="list-disc pl-5 mb-4">
          <li>Upon the provision of consent.</li>
        </ul>

        <p className="mb-8">Retention period: We retain this data until the service is terminated.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Cookies</h2>

        <p className="mb-4">Our website uses cookies. For more information about cookies, please refer to our Cookie
          Policy.</p>

        <p className="mb-4">We have concluded a data Processing Agreement with Google.</p>
        <p className="mb-4">Google may not use the data for any other Google services.</p>
        <p className="mb-8">The inclusion of full IP addresses is blocked by us.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Security</h2>

        <p className="mb-8">We are committed to the security of personal data. We take appropriate security measures to
          limit abuse of and unauthorized access to personal data. This ensures that only the necessary persons have
          access to your data, that access to the data is protected, and that our security measures are regularly
          reviewed.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Third-party websites</h2>

        <p className="mb-8">This privacy statement does not apply to third-party websites connected by links on our
          website. We cannot guarantee that these third parties handle your personal data in a reliable or secure
          manner. We recommend you read the privacy statements of these websites prior to making use of these
          websites.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Amendments to this privacy statement</h2>

        <p className="mb-8">We reserve the right to make amendments to this privacy statement. It is recommended that
          you consult this privacy statement regularly in order to be aware of any changes. In addition, we will
          actively inform you wherever possible.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Accessing and modifying your data</h2>

        <p className="mb-4">If you have any questions or want to know which personal data we have about you, please
          contact us. You can contact us by using the information below. You have the following rights:</p>

        <ul className="list-disc pl-5 mb-8">
          <li>You have the right to know why your personal data is needed, what will happen to it, and how long it will
            be retained for.
          </li>
          <li>Right of access: You have the right to access your personal data that is known to us.</li>
          <li>Right to rectification: you have the right to supplement, correct, have deleted or blocked your personal
            data whenever you wish.
          </li>
          <li>If you give us your consent to process your data, you have the right to revoke that consent and to have
            your personal data deleted.
          </li>
          <li>Right to transfer your data: you have the right to request all your personal data from the controller and
            transfer it in its entirety to another controller.
          </li>
          <li>Right to object: you may object to the processing of your data. We comply with this, unless there are
            justified grounds for processing.
          </li>
        </ul>

        <p className="mb-8">Please make sure to always clearly state who you are, so that we can be certain that we do
          not modify or delete any data of the wrong person.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Submitting a complaint</h2>

        <p className="mb-8">If you are not satisfied with the way in which we handle (a complaint about) the processing
          of your personal data, you have the right to submit a complaint to the Data Protection Authority.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Contact details</h2>

        <address className="not-italic mb-8">
          Rentattest c/o Copilr Studio bv<br/>
          BE 0790.840.406<br/>
          Berthoutstraat 5<br/>
          2580 Putte<br/>
          Belgium<br/>
          <a href="http://www.rentattest.be" className="text-blue-600 hover:underline">www.rentattest.be</a><br/>
          <a href="mailto:info@rentattest.be" className="text-blue-600 hover:underline">info@rentattest.be</a>
        </address>
      </div>
      <Footer/>
    </div>
  );
}
