import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {PageNames} from "./pages";

export function Mollie() {
  const [timer, setTimer] = useState(3);
  const history = useHistory()

  useEffect(() => {
    if (!timer) {
      history.push({
        pathname: PageNames.PAYMENT_RECEIVED,
      });
    }
    let counter = setInterval(() => setTimer(timer => timer - 1), 1000);
    return () => {
      clearInterval(counter);
    };
  }, [timer, history]);

  return (
    <div
      className="flex min-h-screen justify-center">
      <div className="mt-4 flex justify-center items-center flex-col">
        <img src="mollie-vector-logo.svg" alt="mollie logo" className="block w-32"/>
        <span className="mb-4 text-center text-3xl">{timer}</span>
        <span className="text-center font-bold">Processing payment...</span>
      </div>
    </div>
  )
}
