import {Config} from "cookie-though/dist/types/types";

export const config:Config = {
  "policies": [
    {
      "id": "essential",
      "label": "Essentiële cookies",
      "description": "Essentiële cookies zodat de website correct functioneert.",
      "category": "essential",
    },
    {
      "id": "statistics",
      "label": "Analytisch",
      "category": "statistics",
      "description": "Door analytische cookies kunnen we de gebruikerservaring verbeteren.",
    },
  ],
  "essentialLabel": "Always on",
  "permissionLabels": {
    "accept": "Opslaan",
    "acceptAll": "Alle cookies aanvaarden",
    "decline": "Enkel essentiële cookies"
  },
  "cookiePreferenceKey": "cookie-preferences",
  "header": {
    "title": "Deze website gebruikt cookies",
    "subTitle": "",
    "description": "We maken gebruik van cookies om gegevens m.b.t. de prestaties en het gebruik van deze website te verzamelen & analyseren."
  },
  "cookiePolicy": {
    "url": "https://solvattest.be/cookie-policy",
    "label": "Lees onze cookie policy",
  },
  "customizeLabel": "Aanpassen"
};
