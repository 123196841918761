import React, {useEffect, useState} from "react";
import {RentattestCopyrightFooter} from "../components/RentattestCopyrightFooter";
import {FieldErrors, useForm, UseFormRegisterReturn} from "react-hook-form";
import {useHistory, useLocation} from "react-router-dom";
import {FetchDataState, FetchingNRNFailed} from "./FetchingNRNFailed";
import {IFetchNRN} from "../application/FetchNRN";
import {IFormDataService, RentattestFormObject} from "../application/FormDataService";
import {RequestAttest, RequestAttestRequest, RequestAttestResponseStatus} from "../application/RequestAttest";
import {PageNames} from "./pages";
import {useTranslation} from "react-i18next";
import {LanguageSelector} from "../components/LanguageSelector";

enum RequestState {
  REQUESTING = "REQUESTING",
  FAILED = "FAILED",
  NONE = "NONE",
}


interface OrderProps {
  fetchNrn: IFetchNRN,
  formdataService: IFormDataService,
  requestAttestService: RequestAttest
}

export function Order({fetchNrn, formdataService, requestAttestService}: OrderProps) {
  const {t} = useTranslation();
  const formData: RentattestFormObject = formdataService.load();
  const reactHistory = useHistory()
  let urlSearchParams = new URLSearchParams(useLocation().search);
  const code = urlSearchParams.get("code");

  const {
    register,
    handleSubmit,
    getValues
    , formState: {errors},
    setValue
  } = useForm({mode: "all"});

  const [loadNRNState, setLoadNRNState] = useState(FetchDataState.LOADING)
  const [requestState, setRequestState] = useState(RequestState.NONE)
  const [orderFormState, setOrderFormState] = useState(formData)
  const [requestErrorMsg, setRequestErrorMsg] = useState("")

  useEffect(() => {
    console.log('useEffect');
    // (async () => {
    fetchNrn.fetch(code)
      .then(result => {
        console.log(result)
        if (result.isOk && result.nrn) {
          setOrderFormState({...orderFormState, nrn: result.nrn})
          setValue("nrn", result.nrn)
          setLoadNRNState(FetchDataState.SUCCESSFUL);
        } else {
          setLoadNRNState(FetchDataState.FAILED);
        }
      }, reason => {
        console.error(reason)
        setLoadNRNState(FetchDataState.FAILED);
      })
      .catch(reason => {
        console.warn("Fetching nrn failed")
      })
    // })();
  }, []);

  interface FormProps {
    nrn: string,
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    monthlyAmount: string,
  }

  const onSubmit = async (data: FormProps) => {
    setRequestState(RequestState.REQUESTING)
    setRequestErrorMsg("")
    console.log(data)
    const requestData = new RequestAttestRequest(
      orderFormState.nrn,
      orderFormState.firstname,
      orderFormState.lastname,
      orderFormState.email,
      orderFormState.phone,
      String(orderFormState.monthlyAmount),
      orderFormState.vouchercode
    )
    const result = await requestAttestService?.requestAttest(requestData)
    console.log(result)
    if (result.status === RequestAttestResponseStatus.SUCCESS) {
      setRequestState(RequestState.NONE)
      reactHistory.push(PageNames.SUCCESS)
    } else {
      setRequestState(RequestState.FAILED)
      if (typeof result === "string") {
        setRequestErrorMsg(result)
      } else {
        setRequestErrorMsg(result.status)
      }
    }
  }


  return (
    <div
      className="bg-header-desktop bg-custom-header-size min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full mb-8">
        <LanguageSelector/>
        <div className="bg-white shadow-md rounded px-4 lg:px-8 pt-6 pb-8 mb-4 mt-2 max-w-md mx-auto sm:max-w-xl">
          <div hidden={loadNRNState !== FetchDataState.LOADING}>
            <p className="text-center my-4">
              {t("orderForm.identifyingWithItsme")}
            </p>
            <p className="text-center my-4">
              {t("orderForm.justAMoment")}
            </p>
          </div>
          <FetchingNRNFailed showFailure={loadNRNState === FetchDataState.FAILED}/>
          <form
            hidden={loadNRNState !== FetchDataState.SUCCESSFUL}
            onSubmit={handleSubmit(onSubmit)}
          >

            <NRNField value={orderFormState.nrn}/>


            <FirstnameField useFormRegisterReturn={register("firstname", {required: true})}
                            onChange={(e) => setOrderFormState({...orderFormState, firstname: e.target.value})}
                            errors={errors}
            />

            <LastnameField useFormRegisterReturn={register("lastname", {required: true})}
                           onChange={(e) => setOrderFormState({...orderFormState, lastname: e.target.value})}
                           errors={errors}
            />

            <EmailField useFormRegisterReturn={register("email", {required: true})}
                        onChange={(e) => setOrderFormState({...orderFormState, email: e.target.value})}
                        errors={errors}
                        label={t('orderForm.email')}
            />

            <EmailField useFormRegisterReturn={register("confirmEmail",
              {
                required: true,
                validate: {
                  emailEqual: value => (value === getValues().email) || 'Email confirmation error!',
                }
              }
            )}
                        onChange={(e) => setOrderFormState({...orderFormState, confirmEmail: e.target.value})}
                        errors={errors}
                        label={t('orderForm.confirmEmail')}
            />

            <PhoneField useFormRegisterReturn={register("phone", {required: true})}
                        onChange={(e) => setOrderFormState({...orderFormState, phone: e.target.value})}
                        errors={errors}
            />

            <MonthlyAmountField useFormRegisterReturn={register("monthlyAmount", {required: true})}
                                onChange={(e) => setOrderFormState({...orderFormState, monthlyAmount: e.target.value})}
                                errors={errors}
            />

            <span className={(requestErrorMsg ? "" : "hidden") + " text-red-400 text-sm"}>{requestErrorMsg}</span>
            <SubmitButton disabled={requestState == RequestState.REQUESTING} text={t('orderForm.requestAttest')}/>
          </form>

        </div>
        <RentattestCopyrightFooter/>
      </div>
    </div>

  );
}

function NRNField(props: {
  value: string
}) {
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="name"
    >
      Rijksregisternummer
    </label>
    <input
      disabled
      type="text"
      value={props.value}
      className="bg-gray-300 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4
                        my-4 block w-full appearance-none leading-normal disabled:opacity-50"
    />
  </div>;
}

function FirstnameField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  errors: FieldErrors
}) {
  const {t} = useTranslation();

  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="firstname"
    >
      {t('orderForm.firstname')}
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="text"
      id="firstname"
      name="firstname"
      placeholder={t('orderForm.firstname')}
      onChange={props.onChange}
    />
    {props.errors.firstname?.type === "required" &&
      <span className="text-red-400 text-sm">{t('orderForm.firstname') + " " + t('orderForm.isRequired')}</span>}
  </div>;
}

function LastnameField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  errors: FieldErrors
}) {
  const {t} = useTranslation();
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="lastname"
    >
      {t('orderForm.lastname')}
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="text"
      id="lastname"
      name="lastname"
      placeholder={t('orderForm.lastname')}
      onChange={props.onChange}
    />
    {props.errors.lastname?.type === "required" &&
      <span className="text-red-400 text-sm">{t('orderForm.lastname') + " " + t('orderForm.isRequired')}</span>}
  </div>;
}

function EmailField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  errors: FieldErrors,
  label?: string
}) {
  const {t} = useTranslation();

  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="email"
    >
      {props.label}
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="email"
      id={props.useFormRegisterReturn.name}
      name={props.useFormRegisterReturn.name}
      placeholder="jane@example.com"
      onChange={props.onChange}
    />
    {props.errors[props.useFormRegisterReturn.name]?.type === "required" &&
      <p className="text-red-400 text-sm">{t('orderForm.email') + " " + t('orderForm.isRequired')}</p>}
    {props.errors[props.useFormRegisterReturn.name]?.type === "emailEqual" &&
      <p className="text-red-400 text-sm">{t('orderForm.email') + " " + t('orderForm.isNotIdentical')}</p>}
  </div>;
}

function PhoneField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  errors: FieldErrors
}) {
  const {t} = useTranslation();

  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="phone"
    >
      {t('orderForm.telephone')}
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="tel"
      id="phone"
      name="phone"
      placeholder={t('orderForm.telephone')}
      onChange={props.onChange}
    />
    {props.errors.lastname?.type === "required" &&
      <span className="text-red-400 text-sm">{t('orderForm.telephone') + " " + t('orderForm.isRequired')}</span>}
  </div>;
}

function MonthlyAmountField(props: {
  useFormRegisterReturn: UseFormRegisterReturn,
  onChange: (e: any) => void,
  errors: FieldErrors
}) {
  const {t} = useTranslation();
  return <div className="mt-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor="monthylAmount"
    >
      {t('orderForm.monthlyAmount')}
    </label>
    <input
      {...props.useFormRegisterReturn}
      className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
      type="number"
      id={props.useFormRegisterReturn.name}
      name={props.useFormRegisterReturn.name}
      placeholder="€500"
      onChange={props.onChange}
    />
    {props.errors.monthlyAmount?.type === "required" &&
      <span className="text-red-400 text-sm">{t('orderForm.monthlyAmount') + " " + t('orderForm.isRequired')}</span>}
  </div>;
}

interface SubmitButtonProps {
  text?: string,
  disabled?: boolean
}

function SubmitButton({text, disabled}: SubmitButtonProps) {
  return <button type="submit" disabled={disabled}
                 className={(disabled ? "bg-primary-grey text-white" : "bg-primary-cadet-blue text-white hover:bg-primary-sky-blue") + " group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-sky-blue mt-4"}>
							<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className={(disabled ? "text-white" : "text-primary-sky-blue group-hover:text-primary-cadet-blue") + " h-5 w-5 "}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fillRule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clipRule="evenodd"/>
								</svg>
							</span>
    {text}
  </button>;
}
