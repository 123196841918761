import {Navbar} from "../components/Navbar";
import React from "react";
import Footer from "../components/Footer";
import {useTranslation} from "react-i18next";

export function CookiePolicy() {
  const {t} = useTranslation();

  return (
    <div className="flex flex-col justify-center min-h-screen mt-32">
      <Navbar/>
      <div className="container relative min-h-screen lg:block pb-10 policy">
        <h1 className="text-4xl font-bold mb-6">{t("cookie-policy")}</h1>
        <p className="text-sm text-gray-600 mb-4">This Cookie Policy was last updated on September 24, 2024 and applies to
          citizens and legal permanent residents of the European Economic Area and Switzerland</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p className="mb-4">www.rentattest.be (hereinafter: "the website") uses cookies and other related technologies
            (for convenience all technologies are referred to as "cookies"). Cookies are also placed by third parties we
            have engaged. In the document below we inform you about the use of cookies on our website.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What are cookies?</h2>
          <p className="mb-4">A cookie is a small simple file that is sent along with pages of this website and stored by
            your browser on the hard drive of your computer or another device. The information stored therein may be
            returned to our servers or to the servers of the relevant third parties during a subsequent visit.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What are scripts?</h2>
          <p className="mb-4">A script is a piece of program code that is used to make our website function properly and
            interactively. This code is executed on our server or on your device.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What is a web beacon?</h2>
          <p className="mb-4">A web beacon (or a pixel tag) is a small, invisible piece of text or image on a website that
            is used to monitor traffic on a website. In order to do this, various data about you is stored using web
            beacons.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Cookies</h2>
          <h3 className="text-xl font-semibold mb-2">5.1 Technical or functional cookies</h3>
          <p className="mb-4">Some cookies ensure that certain parts of the website work properly and that your user
            preferences remain known. By placing functional cookies, we make it easier for you to visit our website.
            This way, you do not need to repeatedly enter the same information when visiting our website and, for
            example, the items remain in your shopping cart until you have paid. We may place these cookies without your
            consent.</p>

          <h3 className="text-xl font-semibold mb-2">5.2 Statistics cookies</h3>
          <p className="mb-4">We use statistics cookies to optimize the website experience for our users. With these
            statistics cookies we get insights in the usage of our website. We ask your permission to place statistics
            cookies.</p>

          <h3 className="text-xl font-semibold mb-2">5.3 Marketing/Tracking cookies</h3>
          <p className="mb-4">Marketing/Tracking cookies are cookies or any other form of local storage, used to create user
            profiles to display advertising or to track the user on this website or across several websites for similar
            marketing purposes.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Placed cookies</h2>
          <ul className="list-disc list-inside mb-4">
            <li>Google reCAPTCHA Functional, Marketing/Tracking</li>
            <li>Marketing/Tracking, Functional, Statistics, Preferences</li>
            <li>Google Analytics Statistics</li>
            <li>Miscellaneous Purpose pending investigation</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Consent</h2>
          <p className="mb-4">When you visit our website for the first time, we will show you a pop-up with an explanation
            about cookies. As soon as you click on "Save preferences", you consent to us using the categories of cookies
            and plug-ins you selected in the pop-up, as described in this Cookie Policy. You can disable the use of
            cookies via your browser, but please note that our website may no longer work properly.</p>

          <h3 className="text-xl font-semibold mb-2">7.1 Manage your consent settings</h3>
          <p className="font-semibold mb-2">Functional (always active)</p>
          <p className="mb-4">The technical storage or access is strictly necessary for the legitimate purpose of enabling
            the use of a specific service explicitly requested by the subscriber or user, or for the sole purpose of
            carrying out the transmission of a communication over an electronic communications network.</p>

          <p className="font-semibold mb-2">Statistics</p>
          <p className="mb-4">The technical storage or access that is used exclusively for anonymous statistical purposes.
            Without a subpoena, voluntary compliance on the part of your Internet Service Provider, or additional
            records from a third party, information stored or retrieved for this purpose alone cannot usually be used to
            identify you.</p>

          <p className="font-semibold mb-2">Marketing</p>
          <p className="mb-4">The technical storage or access is required to create user profiles to send advertising, or to
            track the user on a website or across several websites for similar marketing purposes.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Enabling/disabling and deleting cookies</h2>
          <p className="mb-4">You can use your internet browser to automatically or manually delete cookies. You can also
            specify that certain cookies may not be placed. Another option is to change the settings of your internet
            browser so that you receive a message each time a cookie is placed. For more information about these
            options, please refer to the instructions in the Help section of your browser. Please note that our website
            may not work properly if all cookies are disabled. If you do delete the cookies in your browser, they will
            be placed again after your consent when you visit our websites again.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Your rights with respect to personal data</h2>
          <p className="mb-4">You have the following rights with respect to your personal data:</p>
          <ul className="list-disc list-inside mb-4">
            <li>You have the right to know why your personal data is needed, what will happen to it, and how long it
              will be retained for.
            </li>
            <li>Right of access: You have the right to access your personal data that is known to us.</li>
            <li>Right to rectification: you have the right to supplement, correct, have deleted or blocked your personal
              data whenever you wish.
            </li>
            <li>If you give us your consent to process your data, you have the right to revoke that consent and to have
              your personal data deleted.
            </li>
            <li>Right to transfer your data: you have the right to request all your personal data from the controller
              and transfer it in its entirety to another controller.
            </li>
            <li>Right to object: you may object to the processing of your data. We comply with this, unless there are
              justified grounds for processing.
            </li>
          </ul>
          <p className="mb-4">To exercise these rights, please contact us. Please refer to the contact details at the bottom
            of this Cookie Policy. If you have a complaint about how we handle your data, we would like to hear from
            you, but you also have the right to submit a complaint to the supervisory authority (the Data Protection
            Authority).</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact details</h2>
          <p className="mb-2">Rentattest c/o Copilr Studio bv</p>
          <p className="mb-2">BE 0790.840.406</p>
          <p className="mb-2">Berthoutstraat 5</p>
          <p className="mb-2">2580 Putte</p>
          <p className="mb-2">Belgium</p>
          <p className="mb-2"><a href="https://www.rentattest.be">www.rentattest.be</a></p>
          <p className="mb-2"><a href="mailto:info@rentattest.be"></a>info@rentattest.be</p>
        </section>

        <p className="text-sm text-gray-600">
          <p>This Cookie Policy was synchronized with <a href="https://cookiedatabase.org">cookiedatabase.org</a> cookiedatabase.org on September 17, 2024</p>
        </p>
      </div>
      <Footer/>
    </div>


  );
}
