export interface ValidateVouchercode {
  validate(vouchercode: string): Promise<VoucherValidationResult>
}

// Responses from webhook:
// 200 - {"status": "accepted"}
// 401 - {"status": "invalid authorization"}
// 404 - {"status": "invalid voucher"}
interface VoucherValidationResult {
  status: "accepted" | "invalid authorization" | "invalid voucher" | "validation aborted"
}

export class ValidateVouchercodeMake implements ValidateVouchercode {
  private newAbortController: AbortController | undefined;

  async validate(vouchercode: string): Promise<VoucherValidationResult> {
    this.newAbortController?.abort();
    this.newAbortController = new AbortController();
    if (vouchercode.length !== 17) {
      return {status: "invalid voucher"}
    }
    return fetch("https://hook.eu2.make.com/x4vs3myp1kd9wb466g3iy7s9uxc7gsoj", {
      signal: this.newAbortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Authorization: "G7^bXA2$kiL9",
        Voucher: vouchercode
      })
    })
      .then(value => value.json())
      .catch(reason => {
        console.log(reason);
        return {status: "validation aborted"}
      });
  }

}
