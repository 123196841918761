export class AppConfig {
  private static instance: AppConfig;
  public apiBaseUrl: string;
  public appBaseUrl: string;
  public itsmeEnvironment: string;
  public itsmeClientId: string;
  public itsmeLoginScope: string;

  private constructor() {
    if (window.location.origin.includes("dev.solvattest.be") || window.location.origin.includes("localhost")) {
      this.apiBaseUrl = "https://dev-api.solvattest.be"
      this.appBaseUrl = "https://www.dev.rentattest.be"
      this.itsmeEnvironment = "e2e"
      this.itsmeClientId = "qenZPXKSwf"
      this.itsmeLoginScope = "GDWGENTSBX_LOGIN"
    } else {
      this.apiBaseUrl = "https://api.solvattest.be"
      this.appBaseUrl = "https://www.rentattest.be"
      this.itsmeEnvironment = "prd"
      this.itsmeClientId = "mey6pV3edA"
      this.itsmeLoginScope = "GDWGENTPRD_LOGIN"
    }
  }

  public static getInstance(): AppConfig {
    if (!AppConfig.instance) {
      AppConfig.instance = new AppConfig();
    }

    return AppConfig.instance;
  }
}
