import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import nl from './translations/nl.json';
import fr from './translations/fr.json';
import en from './translations/en.json';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['nl', 'en', 'fr'],
    fallbackLng: "nl",
    debug: true,
    load: 'all',
    resources: {nl: {translation: nl}, fr: {translation: fr}, en: {translation: en}},
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
