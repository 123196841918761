class FetchNRNResult {
  isOk: boolean;
  orderId: string | undefined;
  nrn: string | undefined;

  constructor(isOk: boolean, nrn?: string, orderId?: string) {
    this.isOk = isOk;
    this.nrn = nrn;
    this.orderId = orderId;
  }

  public static success(nrn: string, orderId: string) {
    return new FetchNRNResult(true, nrn, orderId)
  }

  public static failed() {
    return new FetchNRNResult(false)
  }
}

export interface IFetchNRN {
  fetch(code: string | null): Promise<FetchNRNResult>
}

export class InMemFetchNRN implements IFetchNRN {
  fetch(code: string | null): Promise<FetchNRNResult> {
    console.log(`code : ${code}`)
    if (!code) {
      return Promise.reject("No code provided")
    }
    return Promise.resolve(FetchNRNResult.success("01234567", "fake"))
  }

}

export class FetchNRN implements IFetchNRN {
  private readonly apiBaseUrl: string;

  constructor(apiBaseUrl: string) {
    this.apiBaseUrl = apiBaseUrl
  }

  async fetch(code: string): Promise<FetchNRNResult> {
    if (code) {
      let body: { nrn: string, orderId: string };
      try {
        let url = `${this.apiBaseUrl}/itsme?code=${code}`
        let response: Response = await fetch(url)
        body = await response.json();
        return FetchNRNResult.success(body.nrn, body.orderId)
      } catch (e) {
        console.error("Request failed", e);
        return FetchNRNResult.failed()
      }
    } else {
      console.error(`Code is undefined : ${code}`);
      return FetchNRNResult.failed()
    }
  }
}
