import React, {useState} from "react";
import {ItsmeButton} from "../components/ItsmeButton";
import {RentattestCopyrightFooter} from "../components/RentattestCopyrightFooter";
import {VoucherCodeField} from "../components/VoucherCodeField";
import {ValidateVouchercode} from "../application/ValidateVouchercodeMake";
import {IFormDataService} from "../application/FormDataService";
import {useTranslation} from "react-i18next";
import {LanguageSelector} from "../components/LanguageSelector";

interface IsmeProps {
  validationService: ValidateVouchercode,
  formdataService: IFormDataService
}

export function Itsme({validationService, formdataService}: IsmeProps) {
  const [validationSucceededState, setValidationSucceededState] = useState(false)
  const {t} = useTranslation();

  function ValidationSucceededCallback(state: boolean) {
    setValidationSucceededState(state)
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <LanguageSelector/>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-2 max-w-md mx-auto sm:max-w-xl">
          <VoucherCodeField validationService={validationService}
                            validationSucceededCallback={ValidationSucceededCallback}
                            formdataService={formdataService}
          />
          <ItsmeButton disabled={!validationSucceededState} text={t('orderForm.identifyItsme')}/>
        </div>
        <RentattestCopyrightFooter/>
      </div>
    </div>
  )
}
