import React from "react";
import {useTranslation} from "react-i18next";

export function What() {
  const {t} = useTranslation();
  return (
    <section id="what" className="py-14 bg-neutral-light-grayish-blue lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid mb-12 lg:grid-cols-2 lg:mb-16">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              {t('what.title')}
            </h2>
            <p className="text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              {t('what.2')}
            </p>
            <p className="mt-4 text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              {t('what.3')}
            </p>
            <p className="mt-4 text-sm leading-loose text-neutral-grayish-blue lg:text-base">
              {t('what.4')}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-9 lg:gap-6 lg:grid-cols-4">
          <div className="justify-center">
            <div className="flex justify-center lg:justify-start">
              <img src="/icons/icon-online.svg" alt=""/>
            </div>

            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-6 lg:text-xl lg:font-bold">
              {t('motivation.1.title')}
            </h2>
            <div className="text-sm font-light leading-5 break-words text-neutral-grayish-blue lg:text-base">
              {t('motivation.1.subtitle')}
            </div>
          </div>
          <div className="justify-center">
            <div className="flex justify-center lg:justify-start">
              <img src='/icons/icon-budgeting.svg' alt=""/>
            </div>

            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-6 lg:text-xl lg:font-bold">
              {t('motivation.2.title')}
            </h2>
            <div className="text-sm font-light leading-5 break-words text-neutral-grayish-blue lg:text-base">
              {t('motivation.2.subtitle')}
            </div>
          </div>
          <div className="justify-center">
            <div className="flex justify-center lg:justify-start">
              <img src='/icons/icon-onboarding.svg' alt=""/>
            </div>

            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-6 lg:text-xl lg:font-bold">
              {t('motivation.3.title')}
            </h2>
            <div className="text-sm font-light leading-5 break-words text-neutral-grayish-blue lg:text-base">
              {t('motivation.3.subtitle')}
            </div>
          </div>
          <div className="justify-center">
            <div className="flex justify-center lg:justify-start">
              <img src='/icons/icon-api.svg' alt=""/>
            </div>

            <h2 className="py-4 text-lg text-primary-dark-green lg:pt-9 lg:pb-6 lg:text-xl lg:font-bold">
              {t('motivation.4.title')}
            </h2>
            <div className="text-sm font-light leading-5 break-words text-neutral-grayish-blue lg:text-base">
              {t('motivation.4.subtitle')}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
