import React from "react";
import {VraagSolvabiliteitsAttestAanButton} from "./VraagSolvabiliteitsAttestAanButton";
import {useTranslation} from "react-i18next";


export function Hero() {
  const {t} = useTranslation();
  return (
    <section id="hero" className="relative">
      <Background/>
      <div className="flex items-center container relative z-20 min-h-screen lg:h-screen lg:block">
        <div className="flex flex-col justify-center h-full lg:flex-row lg:justify-between gap-8">
          <div
            className="flex flex-col order-2 justify-center pb-4 h-full lg:pb-0 lg:w-96 lg:justify-center lg:order-none">
            <Body></Body>
          </div>
          <div className="flex flex-1 order-1 items-center justify-center flex-col
          align-middle lg:order-none lg:h-auto lg:mt-0 mt-32 text-primary-cadet-blue text-2xl">
            <img className="w-8/12 mb-3" src="/rentattest-hero-image.png"
                 alt="rentattest.be eerlijke screening, zorgeloos verhuren"/>
            <p>{t('hero.title-top')}</p>
            <p>{t('hero.title-bottom')}</p>
          </div>
        </div>
      </div>
    </section>
  );
}


function Body() {
  const {t} = useTranslation();
  return (
    <div
      className="flex flex-col justify-center items-center h-1/2 text-center lg:items-start lg:text-left">
      <h1 className="mb-7 leading-5 text-primary-dark">
        {t('hero.1')}
      </h1>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        {t('hero.2')}
      </p>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        {t('hero.3')}
      </p>
      <VraagSolvabiliteitsAttestAanButton/>
      <p className="mt-7 mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        {t('hero.4')}
      </p>
      <p className="mb-7 text-xs leading-5 text-primary-dark lg:text-neutral-grayish-blue lg:text-base">
        <a href="mailto:info@rentattest.be">
          {t('hero.5')}
        </a>
        <span>
          {t('hero.6')}
        </span>
      </p>
    </div>
  )
}

function Background() {
  return (
    <div
      className="absolute w-full min-h-full lg:h-full bg-left bg-no-repeat bg-header-desktop bg-custom-header-size lg:block"/>
  )
}

