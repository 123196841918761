import {useTranslation} from "react-i18next";

export function LanguageSelector() {
  const {i18n} = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const isSelected = (lang: string) => {
    if (i18n.language == lang) {
      return "font-bold"
    } else {
      return ""
    }
  }

  return (
    <ul className="flex justify-end text-sm">
      <li className="mr-2 text-primary-sky-blue hover:text-primary-cadet-blue">
        <button className={isSelected("nl")} onClick={() => changeLanguage('nl')}>NL</button>
      </li>
      <li>|</li>
      <li className="mr-2 ml-2 text-primary-sky-blue hover:text-primary-cadet-blue">
        <button className={isSelected("fr")} onClick={() => changeLanguage('fr')}>FR</button>
      </li>
      <li>|</li>
      <li className="ml-2 text-primary-sky-blue hover:text-primary-cadet-blue">
        <button className={isSelected("en")} onClick={() => changeLanguage('en')}>EN</button>
      </li>
    </ul>
  )
}
