import React from 'react';
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import Root from "./Root";

const history = createBrowserHistory();

function App() {
  return (
    <Router {...{history}}>
      <Root/>
    </Router>
  );
}

export default App;
