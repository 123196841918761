import React from "react";
import Footer from "../components/Footer";
import {Hero} from "../components/Hero";
import {What} from "../components/What";
import {Navbar} from "../components/Navbar";
import {How} from "../components/How";
import {Who} from "../components/Who";
import {useTranslation} from "react-i18next";

export function Home()
{
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center min-h-screen text-white">
      <Navbar/>
      <div className="overflow-hidden relative">
        <Hero/>
        <What/>
        <How/>
        <Who/>
      </div>
      <div className="container flex flex-col items-center text-xl">
        <section
          className="flex flex-col items-center py-10 lg:py-20 w-full lg:justify-center text-gray-800 text-center">
          <p className="mb-2">
            {t('looking-for-a-rentattest-real-estate-agent')}
          </p>
          <p className="mb-2">
            {t('becoming-a-rentattest-real-estate-agent')}
          </p>
          <p className="mb-2">
            <span>
            {t('contact.1')}
            </span>
            <a href="mailto:info@rentattest.be" className="ml-1 mr-1">
            {t('contact.2')}
            </a>
            <span>
            {t('contact.3')}
            </span>
          </p>
        </section>
      </div>
      <Footer/>
    </div>
  )
}
