import {useTranslation} from "react-i18next";
import {LanguageSelector} from "../components/LanguageSelector";
import React from "react";

export function Success() {
  const {t} = useTranslation();

  return (
    <div
      className="bg-header-desktop bg-custom-header-size min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full mb-8">
        <LanguageSelector/>
        <div
          className="bg-white shadow-md rounded px-4 lg:px-8 pt-6 pb-8 mb-4 mt-2 max-w-md mx-auto sm:max-w-xl text-center">
          <p className="mb-3">
            {t("orderForm.success.p1")}
          </p>
          <p className="mb-3">
            {t("orderForm.success.p2")}
          </p>
          <p>
            {t("orderForm.success.p3")}
          </p>
        </div>
      </div>
    </div>
  )
}
