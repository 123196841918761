import React from "react";
import {useTranslation} from "react-i18next";

export function Who() {
  const {t} = useTranslation();
  return (
    <section id="who" className="py-10 bg-neutral-light-grayish-blue lg:py-24">
      <div className="container text-center lg:text-left">
        <div className="grid">
          <div className="col-span-1">
            <h2 className="pb-5 text-3xl lg:text-4xl text-primary-dark-green">
              {t('who.title')}
            </h2>
            <h3 className="text-gray-800 font-bold mb-7">
              {t('who.partner')}
            </h3>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              {t('who.p1')}
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              {t('who.p2')}
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              {t('who.p3')}
            </p>

            <h3 className="text-gray-800 font-bold mb-7 mt-7">
              {t('who.bailiff')}
            </h3>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              {t('who.b1')}
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              {t('who.b2')}
            </p>

            <p className="text-sm leading-5 text-neutral-grayish-blue lg:text-base mb-2">
              {t('who.b3')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
