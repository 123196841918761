import React from "react";
import {useTranslation} from "react-i18next";

export function RentattestCopyrightFooter() {
  const year = new Date().getFullYear()
  const {t} = useTranslation();

  return (
    <div className="text-center text-primary-sky-blue text-xs">
          <span>
            &copy;{year}  <a
            className="text-primary-cadet-blue"
            href="https://www.rentattest.be/"
            target="_blank"
            rel="noreferrer">
        Rentattest
      </a>. {t('orderForm.rightsReserved')}
          </span>
      <span className="text-primary-dark mx-1">|</span>
      <a className="hover:text-primary-cadet-blue"
         href="/privacy-policy"
      > Privacy Policy
      </a>
      <span className="text-primary-dark mx-1">|</span>
      <a className="hover:text-primary-cadet-blue"
         href="/cookie-policy"
      > Cookie Policy
      </a>
    </div>

  )
}
